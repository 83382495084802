<template>
  <footer class="footer" v-if="$route.meta.onlyAuthUser !== true">
    <div class="footer-main cbiz-bg section">
      <div class="container">
        <div class="row">
          <div class="widgets">
            <!-- First Column -->
            <div class="col-xs-12 col-sm-12 col-md-4">
              <div class="widget logo">
                <div class="wi-title"></div>
                <div class="wi-content">
                  <p>
                    INCUBE provides supply chain mobility solutions focusing on trainability and food safety, covering
                    track, trace, planning, route optimization, warehouse management, sales and distribution (cash van)
                  </p>
                  <ul class="wi-list">
                    <li>
                      <a href="https://goo.gl/maps/2e6rWya28mkM3AgX7"
                        ><i aria-hidden="true" class="fa fa-map-marker"></i>Get Directions</a
                      >
                    </li>
                    <li>
                      <a href="mailto:marketing@e-incube.com"
                        ><i class="fa fa-envelope-o"></i>marketing@e-incube.com</a
                      >
                    </li>
                    <li>
                      <a href="tel:+97148876675"><i class="fa fa-phone"></i>+97148876675 </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <!-- Second Column -->
            <div class="col-xs-12 col-sm-12 col-md-4">
              <div class="widget">
                <div class="wi-title">
                  <h5>Popular Tags</h5>
                </div>
                <div class="wi-content wi-tags">
                  <ul>
                    <li><a>Hardware</a></li>
                    <li><a>Track And Trace</a></li>
                    <li><a>Solutions</a></li>
                    <li><a>Mobile Printer</a></li>
                    <li><a>Desktop Printer</a></li>
                  </ul>
                </div>
              </div>
              <div class="widget">
                <div class="wi-title">
                  <h5>Follow Us</h5>
                </div>
                <div class="wi-content wi-social">
                  <ul>
                    <li>
                      <a href="https://www.facebook.com/InCubeCo" target="_blank">
                        <i class="fa normal-show fa-facebook"></i>
                        <i class="fa hover-show fa-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.linkedin.com/company/incubefzco" target="_blank">
                        <i class="fa normal-show fa-linkedin"></i>
                        <i class="fa hover-show fa-linkedin"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://wa.me/message/FDX2XP3QXPXSN1" target="_blank">
                        <i class="fa normal-show fa-whatsapp"></i>
                        <i class="fa hover-show fa-whatsapp"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.youtube.com/channel/UCwB2XHEcS4I-3JLm9fwEC6A" target="_blank">
                        <i class="fa normal-show fa-youtube"></i>
                        <i class="fa hover-show fa-youtube"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <!-- Third Column -->
            <div class="col-xs-12 col-sm-12 col-md-4">
              <div class="wi-title">
                <h5>Our Visitor</h5>
              </div>
              <form ref="myform" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="copyright">
      <div class="container">
        INCUBE FZCO | {{ GetCurrentYear() }} All rights reserved.
        <router-link :to="'Privacy-Policy'">Privacy Policy</router-link>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  mounted() {
    let calendlyCSS = document.createElement("link");
    calendlyCSS.rel = "stylesheet";
    calendlyCSS.href = "https://assets.calendly.com/assets/external/widget.css";
    document.head.appendChild(calendlyCSS);

    let calendlyScript = document.createElement("script");
    calendlyScript.src = "https://assets.calendly.com/assets/external/widget.js";
    calendlyScript.async = true;
    calendlyScript.onload = () => {
      Calendly.initBadgeWidget({
        url: "https://calendly.com/social-e-incube",
        text: "Book a live demo now",
        color: "#26446e",
        textColor: "#ffffff",
        branding: true,
      });

      const style = document.createElement("style");
      style.innerHTML = `
        .calendly-badge-widget {
          bottom: 60px !important; 
          border-radius: 25px !important; 
          transition: all 0.3s ease-in-out; 
        }
        
        .calendly-badge-widget:hover {
          background-color: rgba(38, 68, 110, 0.8) !important; 
          transform: translateY(-5px); 
          border-radius: 25px !important;
        }
      `;
      document.head.appendChild(style);
    };
    document.body.appendChild(calendlyScript);

    let revolverMapScript = document.createElement("script");
    revolverMapScript.setAttribute(
      "src",
      "//rf.revolvermaps.com/0/0/8.js?i=5t2gd8ahp8g&amp;m=0&amp;c=9d8b63&amp;cr1=ff0000&amp;f=arial&amp;l=33&amp;as=100"
    );
    this.$refs.myform.appendChild(revolverMapScript);
  },
  methods: {
    GetCurrentYear() {
      return new Date().getFullYear();
    },
  },
};
</script>


